import React from 'react'
import { Link } from 'gatsby'
import classes from './footer.module.css'

const Footer = () => {

  return (
    <footer
      style={{
        backgroundColor: '#f4f4f7',
        borderTop: '2px solid #efefef',
        margin: `0 auto`,
        padding: `1.45rem 1.0875rem 1rem`,
        width: '100%'
      }}
    >
      <div className={classes.Footer}>
        <Link
          to="/"
          style={{
            color: `#000`,
            fontSize: '2.25rem',
            fontWeight: 700,
            lineHeight: 1.1,
            textDecoration: `none`,
          }}
        >
          News
        </Link>

        <div className={classes.FooterMenuWrapper}>
          <ul className={classes.FooterMenu}>
            <li><Link to="/">News Home</Link></li>
            <li><Link to="/">US</Link></li>
            <li><Link to="/">World</Link></li>
            <li><Link to="/">Politics</Link></li>
            <li><Link to="/">Health</Link></li>
            <li><Link to="/">Science</Link></li>
            <li><Link to="/">Originals</Link></li>
            <li><Link to="/">Login</Link></li>
          </ul>
        </div>
      </div>
      <p className={classes.FooterCopyright}>© {new Date().getFullYear()} Newswebsite</p>
    </footer>
  )
}

export default Footer
