import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import classes from './header.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import logo from '../images/logo-texas-news.svg'

const Header = ({ siteTitle, setLoginBtnStatus }) => {

  const [ mobileMenuIconToggle, setMobileMenuIconToggle ] = useState(false)

  const mobileToggle = () => {
    setMobileMenuIconToggle(!mobileMenuIconToggle)
  }

  const exitLink = () => {
    const path = `https://www.google.com/`

    navigate(
      path,
      { replace: true }
    )
  }

  const loginBtn = () => {
    setLoginBtnStatus(true)
  }

  let menuIconClass = [classes.MenuBtn]
  let menuClass = [classes.MainMenu]

  if (mobileMenuIconToggle) {
    menuIconClass.push(classes.act)
    menuClass.push(classes.act)
  }

  return (
    <header
      style={{
        borderBottom: '2px solid #efefef',
      }}
    >
      <div className={classes.TopMenu}>
        <div className={classes.TopMenuWrapper}>
          <ul className={classes.TopSubNav}>
            <li><button onClick={() => loginBtn()}>Login</button></li>
            <li><a href="https://weather.com/" target="_blank" rel="noreferrer" onClick={() => exitLink()}>Exit</a></li>
          </ul>
        </div>
      </div>

      <div className={classes.BotMenuWrapper}>
        <div className={classes.BotMenu}>
          <Link
            to="/"
            style={{
              color: `#3c3c3c`,
              fontSize: '2.25rem',
              fontWeight: 700,
              lineHeight: 1.1,
              textDecoration: `none`,
            }}
          >
            <img
              className={classes.mainLogo}
              src={logo}
              alt="Main Logo"
            />
          </Link>

          <button className={menuIconClass.join(' ')} onClick={() => mobileToggle()}>
            <span className={classes.lines}></span>
          </button>

          <div className={classes.MainMenuWrapper}>
            <nav className={menuClass.join(' ')}>
              <ul>
                <li><Link to="/">News Home</Link></li>
                <li><Link to="/">US</Link></li>
                <li><Link to="/">World</Link></li>
                <li><Link to="/">Politics</Link></li>
                <li><Link to="/">Health</Link></li>
                <li><Link to="/">Science</Link></li>
                <li><Link to="/">Originals</Link></li>
                <li><FontAwesomeIcon icon={ faSearch } /></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
